<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { Verbatim } from "~/models/Verbatim";

const { itemsSlider, title, type } = defineProps<{
  itemsSlider: Verbatim[];
  title: string;
  type: string;
}>();

const hasPhoto = computed(() => itemsSlider.some((item) => item.photo && item.photo.url));
</script>

<template>
  <div class="verbatims" :class="[type, { photo: hasPhoto }]">
    <h2 class="verbatims-title">{{ title }}</h2>
    <div class="verbatims-container">
      <SwiperSlider
        v-slot="slotProps"
        :items-slider="itemsSlider"
        :bullets="true"
        :autoplay="false"
        :loop="true"
        :slide-number="1"
        :slide-number-desk="1"
        :speed="400"
        :navigation="true"
        :space-between-desk="500"
      >
        <VerbatimsItem
          :type="type"
          :quote="slotProps.quote"
          :logo-src="slotProps.logoSrc"
          :logo-alt="slotProps.logoAlt"
          :photo-src="slotProps.photoSrc"
          :photo-alt="slotProps.photoAlt"
          :author="slotProps.author"
        />
      </SwiperSlider>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.verbatims {
  position: relative;
  overflow: hidden;
  @include mq(desktop) {
    padding: 0 $gutter * 8;
    margin: $gutter 0;
  }
  .verbatims-container {
    box-shadow: 0px 1px 20px 0px rgba(112, 112, 112, 0.15);
    overflow: visible;
  }
  &.recruteur {
    .verbatims-title {
      color: $etab-color;
      font-weight: 800;
      text-align: center;
      padding: $gutter * 3 $gutter * 6;
    }
  }
  &.bu {
    .verbatims-title {
      font-size: var(--showcase-size-150);
      color: var(--showcase-color-additional-dark);
      font-weight: 800;
      text-align: center;
      padding: $gutter * 3 $gutter * 6 var(--showcase-size-175);
      @include mq(desktop) {
        padding: $gutter * 3 $gutter * 6 var(--showcase-size-475);
      }
    }
  }
  &.dark {
    .verbatims-title {
      font-size: var(--showcase-size-150);
      color: var(--showcase-color-additional-dark);
      font-weight: 800;
      text-align: center;
      padding: 0 $gutter * 6 var(--showcase-size-175);
      @include mq(desktop) {
        padding: var(--showcase-size-175) $gutter * 6 var(--showcase-size-175);
      }
    }
  }
  &.candidat {
    .verbatims-title {
      color: $primary-color;
      font-weight: 800;
      text-align: center;
      padding: $gutter * 3 $gutter * 6;
    }
    .verbatims-container {
      margin-bottom: $gutter * 4;
    }
  }
}
</style>
